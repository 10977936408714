import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  postIssueTracker(call, obj, showMessage) {
    return this.getData(`/co/issue_tracker`, call, obj, showMessage);
  },

  getIssueImages(call, sys_file, showMessage) {
    return this.getData(`/co/file_url?sys_file=${sys_file}`, call, showMessage);
  },
  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;

        if (this.isJson(res?.data?.body?.message))
          return JSON.parse(res?.data?.body?.message)
        else
          return res?.data?.body?.message
            ;
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        console.log(err)
        return {};
      }
    } else if (res.data.body.message == null) {
      if (res.data.body !== null) {
        LoaderDialog.visible = false;
        return res?.data?.body;
      } else {
        LoaderDialog.visible = false;
        return JSON.parse('{"message":"NA"}');
      }
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        console.log(err)
        return {};

      }
    }


  },
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

};
