<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="11" class="px-1 pt-2">
      <v-btn small @click="clearFilter" class="my-0 pa-1 mt-1 mx-2 btn-style float-right" elevation="1" outlined color="secondary">
        <v-icon small dark title="Clear Filter">mdi mdi-filter-remove</v-icon>
      </v-btn>       
      <v-menu rounded="lg" offset-y v-for="(mainItem) in arrayData" :key="mainItem.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" outlined class="float-right ml-1 rounded-pill px-2">{{ mainItem.label }}:
            <span id="button-text-order" class="f-bold f-11 pl-1 uppercase-text">{{ mainItem.id==currentState?.id?displayValue:"" }}</span><v-icon
              class="ml-1 mr-n2">mdi-chevron-down</v-icon></v-btn>
        </template>
        <v-list class="f-10 py-0 mx-0">
          <v-list-item v-for="(item, index) in mainItem.array" :key="index" @click="filterSelected(item,mainItem.id)">
            <v-list-item-text class="f-bold f-11 uppercase-text">{{ item }}</v-list-item-text>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

  </v-row>
</template>
<script>
import { bus } from "../../main";
export default {
  name: 'filterComp',
  props: {
    arrayData:[]
  },
  data() {
    return {
      currentState:[],
      displayValue:""
    }
  },
  created() {
    bus.$on('clearFilter', (data) => {
      if(data)
        this.displayValue="" 
    })

  },
  mounted() {
  },

  methods: {
    filterSelected(name, id) {
      this.currentState={
        id:id,
        value:name
      }
      this.displayValue=name
      this.$emit("filteredValues", name, id)
    },
    clearFilter() {
      this.displayValue=""
      this.$emit("filteredValues", "clear", 0);
    }
  },
}
</script>