import breadcrumbComp from "../../common/breadcrumb-comp";
import issueService from "./report-service.js";
import EncryptUtility from "../../utility/js/encrypt-utility";
import Utility from "../../../shared/utility.js";
import DisplayTextData from "../../common/display-text-data.vue";
import FilterComp from "../../common/filter-comp.vue";
export default {
  data() {
    return {
      arrayData:[],
      displayTextArray:[],

      backSlash: true,
      customPage: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchBugs: "",
      isFormValid: false,
      showClosedSwitch:false,
      totalRecords: 0,
      allBugData: [],
      changeBugStatusDialog: false,
      bugStatusList: [],
      updatedHistoryTable:[],
      filesTable:[],
      selectedStatus: "",
      issueComments: "",
      uniqueStatus:[],
      reportedBy:[],
      recorded:[],
      tempBugList:[],
      issueData: [],
      required:[(v) => !!v || "Field is required"],
      max150Rule: [(v) => (v || "").length <= 150 || "Max Length of 150 character", (v) => !!v || "Field is required"],
      max1000Rule: [(v) => (v || "").length <= 1000 || "Max Length of 1000 character", (v) => !!v || "Field is required"],
      allBugHeaders: [
        { text: "Ticket Number", align: "start", value: "ticket_number", class: "primary customwhite--text" },
        { text: "Title", align: "start", value: "title", class: "primary customwhite--text" },
        { text: "Description", value: "description", class: "primary customwhite--text" },  
        { text: "Reported By", value: "recorded", class: "primary customwhite--text" },
        { text: "Reported Date ", value: "cdate", class: "primary customwhite--text" },
        { text: "Updated By", value: "updated", class: "primary customwhite--text" },
        { text: "Updated Date ", value: "udate", class: "primary customwhite--text" },
        { text: "Status", value: "status", class: "primary customwhite--text" },
        { text: "Operation", value: "action", class: "primary customwhite--text" },
      ],
      updateHistoryHeader: [
        { text: "Status", align: "start", value: "status", class: "primary customwhite--text" },
        { text: "Updated Date", align: "start", value: "u_date_history", class: "primary customwhite--text" },
        { text: "Updated By", value: "u_by_history", class: "primary customwhite--text" },  
        { text: "Comments", value: "comments", class: "primary customwhite--text" },
      ],
  
      statusMap: {
        'New': { text: 'New', class: 'new' },
        'Duplicate': { text: 'Duplicate', class: 'duplicate' },
        'In-Progress': { text: 'In-Progress', class: 'inprogress' },
        'Dev-Fixed': { text: 'Dev-Fixed', class: 'devfix' },
        'In-Testing': { text: 'In-Testing', class: 'intest' },
        'Resolved': { text: 'Resolved', class: 'resolved' },
        'Closed': { text: 'Closed', class: 'closed' },
        'UserDeleted': { text: 'UserDeleted', class: 'userdelete' },
         'default': { text: 'Inactive', class: 'inactive' }
      },  
      sortBy: "issue_id",
    };
  },
  async mounted() {
    this.getIssueStatusList();
    this.getAllIssueDetails(3);
   
  },
  methods: {
    getStatusText(status) {
      return this.statusMap[status]?.text || this.statusMap.default.text;
    },
    // Function to get CSS class of the text
    getStatusClass(status) {
      return this.statusMap[status]?.class || this.statusMap.default.class;
    },
    //Reset Function
    resetFunction() {
      this.searchBugs = "";
      this.totalRecords = 0;
      this.changeBugStatusDialog = false;
      this.bugStatusList = [];
      this.selectedStatus = "";
      this.issueComments = "";
      this.issueData = [];
      this.getIssueStatusList();
      this.getAllIssueDetails(3);
    },
    //Get All Issue/Bug list
    async getAllIssueDetails(passedValue) {
      this.allBugData = [];
      this.totalRecords = 0;
      let pass = passedValue;
      let issueObj = {
        UserId: parseInt(this.userId),
        pass: parseInt(pass),
      };
      let showMessage = false;
      let issueTrackerData = await issueService.postIssueTracker("post", issueObj, showMessage);
      if (issueTrackerData !== null && issueTrackerData !== undefined && issueTrackerData.message !== "NA") {
        this.allBugData = issueTrackerData.map((obj) => {
          return {
            ...obj,
            cdate: Utility.convertESTToLocal(obj.cdate),
            udate: Utility.convertESTToLocal(obj.udate),
          };
        });
        this.uniqueStatus = [...new Map(this.allBugData.map((item) => [item["status"], item.status])).values()];
        this.reportedBy = [...new Map(this.allBugData.map((item) => [item["updated"], item.updated])).values()]
        this.recorded = [...new Map(this.allBugData.map((item) => [item["recorded"], item.recorded])).values()]

        this.arrayData = [
          { id: 1, label: "Status", array:  this.uniqueStatus },
          { id: 2, label: "Updated By", array: this.reportedBy },
          { id: 3, label: "Recorded By", array: this.recorded },
        ];
        this.tempBugList=  this.allBugData
        this.totalRecords = this.allBugData.length;
      }
    },
    //Close the popup
    closeDialog() {
      this.changeBugStatusDialog = false;
      this.updatedHistoryTable=[];
      this.filesTable=[]
      this.selectedStatus = "";
      this.issueComments = "";
      this.$refs.issueStatusChangeForm.resetValidation();
    },
    //Issue Details
    async issueDetails(item) {
      this.issueData = [];
      this.issueData = item;
      this.changeBugStatusDialog = true;
      let detailsObj={
        pass:7,
        userId:0,
        issue_id:item.issue_id
      }
      let issueTrackerData = await issueService.postIssueTracker("post", detailsObj, false);
     
      this.displayTextArray=[
        { label: "Ticket Number", value: issueTrackerData[0].ticket_number },
        { label: "Current Status", value:issueTrackerData[0]?.status },
        { label: "Reported By", value:issueTrackerData[0].created_by },
        { label: "Reported Date", value: Utility.convertESTToLocal(issueTrackerData[0].cdate)},
        { label: "Page Name", value:issueTrackerData[0].title  },
        { label: "Page URL", value:issueTrackerData[0].url? issueTrackerData[0].url:""  },
        { label: "Updated By", value: issueTrackerData[0].last_updated},
        { label: "Updated Date", value: Utility.convertESTToLocal(issueTrackerData[0].udate)}
      ]
      if(issueTrackerData[0]?.issue_updates!==null)
      this.updatedHistoryTable= issueTrackerData[0]?.issue_updates
      this.updatedHistoryTable.forEach((element) => {
        element.u_date_history = Utility.convertESTToLocal( element.u_date_history);
      });
      if(issueTrackerData[0]?.file_details!==null)
      this.filesTable=issueTrackerData[0]?.file_details
    },
    // show CLosed
     showClosed(){
        this.showClosedSwitch?this.getAllIssueDetails(8):this.getAllIssueDetails(3)
     },
    //Ge,t Issue status list
    async getIssueStatusList() {
      this.bugStatusList = [];
      this.totalRecords = 0;
      let pass = 4;
      let issueObj = {
        UserId: parseInt(this.userId),
        pass: parseInt(pass),
      };
      let showMessage = false;
      let issueTrackerData = await issueService.postIssueTracker("post", issueObj, showMessage);
      this.bugStatusList = issueTrackerData;
    },
    //Submit Issue change status
    async submitIssueStatus() {
      if (this.$refs.issueStatusChangeForm.validate()){
      let pass = 5;
      let issueObj = {
        UserId: parseInt(this.userId),
        pass: parseInt(pass),
        issue_id: this.issueData.issue_id,
        comments: this.issueComments,
        status: this.selectedStatus,
      };
      let showMessage = true;
      let issueTrackerData = await issueService.postIssueTracker("post", issueObj, showMessage);
      if (issueTrackerData.Result[0]) {
        this.closeDialog();
        this.getAllIssueDetails(3);}
      }
    },
    //Download file function
    async downloadFile(item) {
      let showMessage = true;
      let downloadLink = await issueService.getIssueImages("get", item, showMessage);
      if (downloadLink !== undefined && downloadLink !== null) {
        window.open(downloadLink, "_blank").focus();
      }
    },
    // Filtered values
    async filteredValues(value, type) {
      const filters = {
        0: () =>  this.tempBugList,
        1: (value) =>   this.tempBugList.filter((element) => element.status == value),
        2: (value) =>   this.tempBugList.filter((element) => element.updated == value),
        3: (value) =>   this.tempBugList.filter((element) => element.recorded == value),
      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.allBugData = data;
    },
  },
  components: {
    breadcrumbComp,
    DisplayTextData,
    FilterComp
  },
};
